<!--乡宁旺资讯类列表-->

<template>
    <div class="bestPerson">
        <div class="breadcrumb w-1400">
            <el-breadcrumb separator-class="el-icon-arrow-right">
                <!-- <el-breadcrumb-item :to="{ path: '/xiangningqing'}">乡宁情</el-breadcrumb-item>
                <el-breadcrumb-item :to="{ path: '/talents'}">能人回乡</el-breadcrumb-item>
                <el-breadcrumb-item >三项经验列表</el-breadcrumb-item> -->
                <el-breadcrumb-item v-for="(item,k) in breadDatas[$route.query.type].slice(0,3)" :key="k+'bread'" :to="item.path">{{item.title}}</el-breadcrumb-item> 
            </el-breadcrumb>
        </div>
        
        <div class="p-list w-1400" v-if="pageData.list&&pageData.list.length>0">
            <div class="secondInfoType" v-if="$route.query.secondInfoType">
                分类：{{$route.query.secondInfoType}}
            </div>
            <div class="p-item" v-for="item in pageData.list" :key="item.id">
                <!-- <router-link :to="`/detail?id=${item.id}&type=${$route.query.type}&oParams=${JSON.stringify(pageParams)}`">-->
                <div class="p-pic">
                    <el-image style="width:100%;height:100%" fit="cover" :src="item.photoUrls[0]"></el-image>
                    <!-- <video :src="item.videoUrl" style="width:100%;height:100%" class="videotag"></video> -->
                    <div class="T_video" >
                      <img width="32px" :src="require('../../public/img/video/play.png')" @click="onVideoPlay(`${item.videoUrl}`)" alt="">
                    </div>
                </div>
                <div class="p-info">
                    <div class="p-h4 ellipsis">{{item.videoName}}</div>
                    <div class="p-text" >
                        {{regHtml(item.introduction)}}
                    </div>
                </div>
            </div>
        </div>
        <div class="empty" v-else>
          <el-empty :image-size="300" description="暂无相关数据"></el-empty>
        </div>
        <el-pagination v-if="pageData.total>0" style="margin-bottom:20px" class="pagination" background layout="prev, pager, next,jumper" :total="pageData.total" @current-change="handleCurrentChange"> </el-pagination>
        <!--视频播放窗口-->
        <el-dialog title="" :visible.sync="dialogVideo" width="840px" class="videodlog" append-to-body @close="onVideoClear">
            <i class="el-icon-close icon_btn" @click="dialogVideo = false" style="background:transparent;position:absolute;top:5px;right:10px;font-size:24px;z-index:99999;color:#fff"></i>
            <div class="videoplayer" v-if="dialogVideo">
                <video-player class="bideo-player vjs-custom-skin" ref="videoPlayer" :playsinline="true" :options="playerOptions"></video-player>
            </div>
            <div class="video_List clearfix">
                <ul>
                    <li v-for="item in videoList" class="fl" :key="item+'_key'" style="margin-left:15px">
                        <div class="video_box">
                            <!-- <video :src="item" style="width:100%;height:100%"></video> -->
                            <el-image style="width: 100%; height: 100%" fit="cover" :src="item.photoUrls[0]"></el-image>
                            <div class="video_shade"><img :src="require('../../public/img/video/play.png')" @click="onVideoPlay(item)"></div>
                        </div>
                        <div class="video_title">{{item.substr(item.lastIndexOf('\\')+1)}}</div>
                    </li>
                </ul>
            </div>
        </el-dialog>
        <!-- <videoCanvasVue/> -->
    </div>
</template>
<script>
// import videoCanvasVue from './videoCanvas.vue';
import thirdNav from '@/components/thirdNav'
import {regHtml} from '@/utils/reg'
import {breadList} from '@/utils/bread'
import {GetVideoInfoPage } from "@/api/xiangningqing";
export default {
    components:{
        thirdNav,
    },
    computed:{
        baseUrl:()=>process.env.VUE_APP_PHOTO_URL,
        regHtml:()=>regHtml,
    },
    data(){
        return{
            dialogVideo: false,
            pageParams:{
                currentPage: 1,
                pageSize: 8,
                filter: {
                    type: "专题讲座",
                    // infoType: 256581677129797,
                },
            },
            pageData:{},
            videoList:[],
            breadDatas:breadList
        }
    },
    methods:{
        getPageList(){
            // this.pageParams
            GetVideoInfoPage(this.pageParams).then(res=>{
                res.data.data.list.forEach((d) => {
                    d.photoUrls = d.photoUrls.split(",");
                });
                this.pageData = res.data.data;
            })
        },
        //分页
        handleCurrentChange(page) {
            this.pageParams.currentPage = page;
            this.getPageList();
        },
        onVideoClear(){
            this.videoList = [];
        },
        onVideoPlay(path){
            let _src = Array.isArray(path)?path[0]:path;
            let option = {
                playbackRates: [0.5, 1.0, 1.5, 2.0], // 可选的播放速度
                autoplay: true, // 如果为true,浏览器准备好时开始回放。
                muted: false, // 默认情况下将会消除任何音频。
                loop: false, // 是否视频一结束就重新开始。
                preload: 'auto', // 建议浏览器在<video>加载元素后是否应该开始下载视频数据。auto浏览器选择最佳行为,立即开始加载视频（如果浏览器支持）
                language: 'zh-CN',
                aspectRatio: '16:9', // 将播放器置于流畅模式，并在计算播放器的动态大小时使用该值。值应该代表一个比例 - 用冒号分隔的两个数字（例如"16:9"或"4:3"）
                fluid: true, // 当true时，Video.js player将拥有流体大小。换句话说，它将按比例缩放以适应其容器。
                sources: [
                    {
                        type: "video/mp4", // 类型
                        src: _src // url地址
                    },
                ],
                poster: '', // 封面地址
                notSupportedMessage: '此视频暂无法播放，请稍后再试', // 允许覆盖Video.js无法播放媒体源时显示的默认信息。
                controlBar: {
                    timeDivider: true, // 当前时间和持续时间的分隔符
                    durationDisplay: true, // 显示持续时间
                    remainingTimeDisplay: false, // 是否显示剩余时间功能
                    fullscreenToggle: true // 是否显示全屏按钮
                }
            }
            if(Array.isArray(path)&&path.length>1){this.videoList = path};
            this.playerOptions = option;
            this.dialogVideo = true;
        },
    },
    created(){
        this.getPageList();
    }
}
</script>
<style scoped>
.T_video{
  position:absolute;
  top: 50%;
  left: 50%;
  transform: translate(-50%,-50%);
}
.p-list{
    display: flex;
    flex-wrap: wrap;
}
.empty{
  height: 500px;
}
.secondInfoType{
    font-size: 15px;
    font-weight: 600;
}
.p-text{
    line-height: 36px;
    font-size: 14px;
    text-indent: 30px;
    display:-webkit-box;
    overflow:hidden;
    text-overflow:ellipsis;
    -webkit-line-clamp:3;
    -webkit-box-orient:vertical;
}
.p-h4{
    line-height: 50px;
    font-size: 16px;
    font-weight: bold;
}
.p-info{
    width: 100%;
    padding: 5px 20px;
    overflow: hidden;
    background: #e7e7e7;
    height: 168px;
}
.p-pic{
    width: 100%;
    height: 200px;
    border-radius: 5px;
    position: relative;
}
.p-item{
    padding: 10px;
    border-radius: 5px;
    margin-top: 20px;
    width: 25%;

}
.picbox{
    position: absolute;
    top: -220px;
    left: 0;
    width: 240px;
    height: 220px;
    background: url(../../public/img/xnq/menu1.png) no-repeat center center;
    border: 3px solid #dc5238;
}
.submenu-item:nth-child(1){background: #dc5238;}
.submenu-item:nth-child(2){background: #f89807;}
.submenu-item:nth-child(3){background: #1db59b;}
.submenu-item{
    width: 240px;
    text-align: center;
    color: #fff;
    position: relative;
}
.submenu{
    position: absolute;
    left: 0;
    bottom: 0;
    width: 100%;
    line-height: 50px;
    background: #00a0e9;
    font-size: 22px;
    justify-content: center;
}
.banner{
    height: 630px;
    background: url(../../public/img/xnq/banner1.png) no-repeat center center;
}
.bestPerson header{
    position: relative;
    height: 630px;
}
.bestPerson{
    font-family: '微软雅黑';
}
.breadcrumb >>> .el-breadcrumb .is-link,.breadcrumb >>> .el-breadcrumb .el-breadcrumb__separator,.breadcrumb >>> .el-breadcrumb .el-breadcrumb__inner{
    font-size: 18px;
    font-family: '微软雅黑';
    color: #0e6ac1;
}
.breadcrumb{
    padding: 20px 0 10px 0;
    border-bottom: 1px solid #0e6ac1;
    margin-bottom: 50px;
}
</style>